// extracted by mini-css-extract-plugin
export var lbContainerOuter = "D_vR";
export var lbContainerInner = "D_vS";
export var movingForwards = "D_vT";
export var movingForwardsOther = "D_vV";
export var movingBackwards = "D_vW";
export var movingBackwardsOther = "D_vX";
export var lbImage = "D_vY";
export var lbOtherImage = "D_vZ";
export var prevButton = "D_v0";
export var nextButton = "D_v1";
export var closing = "D_v2";
export var appear = "D_v3";