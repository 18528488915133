// extracted by mini-css-extract-plugin
export var alignLeft = "w_qh d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qj d_fr d_bH d_dx";
export var testimonialsContainer = "w_sK d_dW";
export var testimonialsContainerFull = "w_sL d_dT";
export var testimonialsMainHeader = "w_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "w_jy d_jy";
export var testimonialsComponentSmall = "w_jx d_jx";
export var testimonialsComponentsWrapper = "w_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "w_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "w_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "w_sM d_d7";
export var colEqualHeight = "w_sN d_bz";
export var testimonialsColumnWrapper = "w_jz d_jz d_b3";
export var testimonialsImageWrapper = "w_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "w_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "w_jr d_jr d_Z d_bz";
export var design2TextWrapper = "w_sP d_bC d_bP d_bJ";
export var design3 = "w_sQ d_cD d_H";
export var imageDesign2 = "w_sR";
export var SmallSmall = "w_rB z_rB z_sX z_tj";
export var SmallNormal = "w_rC z_rC z_sX z_tk";
export var SmallLarge = "w_rD z_rD z_sX z_tg";
export var exceptionWeight = "w_sg z_tn";