// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_sB d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_sC d_bz d_bP";
export var storyWrapperFull = "v_sD d_cD";
export var storyWrapperFullLeft = "v_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mv d_hy";
export var storyLeftWrapperCenter = "v_sF d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_sG d_hF";
export var storyHeader = "v_sH d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_sJ d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_rs d_fg d_Z";
export var imageWrapperFull = "v_rt d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qd z_qd z_sX z_s7";
export var SubtitleNormal = "v_qf z_qf z_sX z_s8";
export var SubtitleLarge = "v_qg z_qg z_sX z_s9";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";