// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sh d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_sj d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_sk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_sl d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_sm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_sn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_sp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_sq z_sq";
export var heroExceptionNormal = "t_sr z_sr";
export var heroExceptionLarge = "t_ss z_ss";
export var Title1Small = "t_st z_st z_sX z_sY";
export var Title1Normal = "t_sv z_sv z_sX z_sZ";
export var Title1Large = "t_sw z_sw z_sX z_s0";
export var BodySmall = "t_sx z_sx z_sX z_tf";
export var BodyNormal = "t_sy z_sy z_sX z_tg";
export var BodyLarge = "t_sz z_sz z_sX z_th";